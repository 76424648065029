<template>
    <!-- Small floating button for mobile screens -->
    <button class="btn btn-info btn-round d-lg-none"
        style="position: fixed; top: calc(100vh - 160px); right: 20px; z-index: 100" 
        type="button" data-bs-toggle="offcanvas" 
        data-bs-target="#offcanvasResponsive"
        aria-controls="offcanvasResponsive">
        <font-awesome-icon icon="fa-solid fa-bars" />
    </button>
    <div class="d-none d-lg-flex pt-md-1 pt-lg-1  shadow">
                    <ul class="navbar flex-justify-content-center mx-auto mb-0" style="list-style: none;">
                        <li class="nav-item px-3">
                            <router-link to="/user" class="nav-link d-flex align-items-center">
                                <font-awesome-icon class="me-1" icon="fa-solid fa-house-user" />
                                <span>Home</span>
                            </router-link>
                        </li>
                        <li class="nav-item px-3">
                            <router-link to="/weekly-workouts" class="nav-link d-flex align-items-center">
                                <font-awesome-icon class="me-1" icon="fa-solid fa-calendar-check" />
                                <span>Urris WW</span>
                            </router-link>
                        </li>
                        <li class="nav-item px-3">
                            <router-link to="/user/info" class="nav-link d-flex align-items-center">
                                <font-awesome-icon class="me-1" icon="fa-solid fa-bullseye" />
                                <span>Goals & Merits</span>
                            </router-link>
                        </li>
                        <li class="nav-item px-3">
                            <router-link to="/urris-ass" class="nav-link align-items-center d-flex">
                                <font-awesome-icon class="me-1" icon="fa-solid fa-search" />
                                <span>Activities smart search</span>
                            </router-link>
                        </li>
                        <hr class="sidebar-divider d-none d-md-block">
                    </ul>
                </div>

    <div class="d-flex flex-column h-100" style="min-height: 100vh">
        <div class="d-flex">
           


            <!-- Sidebar/Menu -->
            <div class="offcanvas offcanvas-bottom offcanvas-mobile border-end d-lg-none p-4 px-lg-0 custom-sidebar"
                tabindex="-1" id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel">
                
                <div class="offcanvas-header d-flex justify-content-end d-lg-none  p-0 position-relative">
                    <button type="button" class="btn-close position-absolute top-0 right-0" 
                        data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" 
                        aria-label="Close"></button>
                </div>

                <div class="offcanvas-body pt-md-4 pt-lg-1">
                    <ul class="navbar-nav w-100" id="accordionSidebar">
                        <li class="nav-item">
                            <router-link to="/user" class="nav-link d-flex align-items-center" @click="closeMenu">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-house-user" />
                                <span>Home</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/weekly-workouts" class="nav-link d-flex align-items-center" @click="closeMenu">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-calendar-check" />
                                <span>Urris WW</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/user/info" class="nav-link d-flex align-items-center" @click="closeMenu">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-bullseye" />
                                <span>Goals & Merits</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/urris-ass" class="nav-link align-items-center d-flex" @click="closeMenu">
                                <font-awesome-icon class="me-4" icon="fa-solid fa-search" />
                                <span>Activities smart search</span>
                            </router-link>
                        </li>
                        <hr class="sidebar-divider d-none d-md-block">
                    </ul>
                </div>
            </div>

            <div class="py-4 col-12">
                <slot></slot>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './Footer/Footer.vue'
import { Offcanvas } from 'bootstrap';
export default {
    name: 'LayoutLoggedIn',
    components: {
        Footer
    },
    methods: {
    closeMenu() {
      const offcanvasElement = document.getElementById('offcanvasResponsive');
      const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
      if (offcanvasInstance) {
        offcanvasInstance.hide();
      }
      window.scrollTo(0, 0);
    },
  },
}
</script>

<style scoped>
/* Offcanvas behavior for smaller screens */
@media (max-width: 991px) { /* Bootstrap lg breakpoint */
    .offcanvas-mobile {
        position: fixed !important;
      
    }
}

/* Always visible sidebar for larger screens */
@media (min-width: 992px) {
    .custom-sidebar {
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 300px !important;
        height: auto !important;
        background: white;
        border-radius: 10px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        display: flex !important;
        flex-direction: column;
    }
}
</style>
