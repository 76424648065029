<template>
  <div ref="chartContainer" class="chart-container position-relative">
    <!-- Date selector controls -->
    <div class="date-selectors mb-0 mb-lg-3">
      <div class="d-flex gap-1 align-items-end">
        <div class="mb-2">
          <label for="startDate" class="form-label">Start Date:</label>
          <input type="date" id="startDate" class="form-control form-control-sm"
            :max="new Date().toISOString().split('T')[0]" v-model="customStartDate"
            @modelChange="updateCustomDateRange" />
        </div>
        <div class="mb-2">
          <label for="endDate" class="form-label">End Date:</label>
          <input type="date" id="endDate" class="form-control form-control-sm"
            :max="new Date().toISOString().split('T')[0]" :min="customStartDate" v-model="customEndDate"
            @modelChange="updateCustomDateRange" />
        </div>
        <div class="mb-2">
          <button class="btn btn-sm btn-secondary" @click="resetDates">Reset Dates</button>
        </div>
      </div>
    </div>

   <!-- Data details pop-up -->
   <div v-if="selectedData" class="data-details-box bg-light border-2 border-color-primary">
      
      <p class="mb-1"><strong>
  {{ 
    (() => {
      const startDate = new Date(selectedData.start_date);
      const endDate = new Date(selectedData.end_date);

      // Check if startDate is the first day of the month
      // and endDate is the first day of the next month (even across years)
      const isFullMonth = startDate.getDate() === 1 && 
                          endDate.getDate() === 1 &&
                          (
                            (startDate.getMonth() + 1 === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) ||
                            (startDate.getMonth() === 11 && endDate.getMonth() === 0 && endDate.getFullYear() === startDate.getFullYear() + 1)
                          );

      return isFullMonth
        ? startDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) 
        : `${startDate.toLocaleDateString('sv-SE', { timeZone: "GMT" })} - ${endDate.toLocaleDateString('sv-SE', { timeZone: "GMT" })}`;
    })() 
  }}
</strong></p>

      <p class="mb-0"><strong>Avg Pace:</strong> {{ $secondsToMinutesPerKm(selectedData.avg_speed) }}</p>
      <p class="mb-0"><strong>Sum Distance:</strong> {{ selectedData.sum_distance }} km</p>
    </div>

    <!-- Controls for toggling lines -->
    <div class="d-flex justify-content-center mb-3" v-if="!chartIsPoints">
      <div class="form-check form-check-inline">
        <input class="form-check-input avg-speed" type="checkbox" id="showAvgSpeed" v-model="showAvgSpeed" />
        <label class="form-check-label avg-speed" for="showAvgSpeed">Show Pace</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input sum-distance" type="checkbox" id="showSumDistance" v-model="showSumDistance" />
        <label class="form-check-label sum-distance" for="showSumDistance">Show Sum Distance</label>
      </div>
    </div>

    <!-- SVG container for the graph -->
    <svg ref="svg" class="w-100"></svg>

 
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    keyss: Number,
  },
  data() {
    return {
      showAvgSpeed: true,
      disableHover: false,
      showSumDistance: true,
      customStartDate: null,
      customEndDate: null,
      filteredData: this.data,
      xScale: null,
      margin: { top: 20, right: 50, bottom: 50, left: 50 },
      selectedData: null, // For displaying the hovered data point
      verticalLine: null,  // To store the vertical line element
    };
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    updateCustomDateRange() {
      const start = new Date(this.customStartDate);
      const end = new Date(this.customEndDate);

      if (this.customStartDate && this.customEndDate) {
        this.filteredData = this.data.filter((d) => {
          const date = new Date(d.start_date);
          return date >= start && date <= end;
        });
        this.drawChart();
      }
    },
    resetDates() {
      this.customStartDate = null;
      this.customEndDate = null;
      this.filteredData = this.data;
      this.$emit('brush', { startDate: null, endDate: null, callBack: this.updateCustomDateRange });
    },
    drawChart() {
      console.log(this.keyss);
      const svg = d3.select(this.$refs.svg);
      svg.selectAll("*").remove();

    const margin = this.margin;
    const width = this.keyss > 0 ? window.innerWidth - margin.top - margin.bottom -80: parseInt(d3.select(this.$refs.chartContainer).style("width")) - margin.left - margin.right;
        console.log(window.innerHeight);
    const height = this.keyss > 0 ? window.innerHeight - margin.top - margin.bottom -120: 400 - margin.top - margin.bottom;

    const g = svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Filter out invalid data points
    const validData = this.filteredData.filter(d => d.sum_distance > 0 && d.avg_speed > 0);

    // Scales
    this.xScale = d3.scaleTime()
      .domain(d3.extent(validData, (d) => new Date(d.start_date)))
      .range([0, width]);

    const avgSpeedValues = validData.map(d => d.avg_speed);
    const minAvgSpeed = d3.min(avgSpeedValues);
    const maxAvgSpeed = d3.max(avgSpeedValues);
    const buffer = (maxAvgSpeed - minAvgSpeed) * 0.1;

    const yScaleAvgSpeed = d3.scaleLinear()
      .domain([minAvgSpeed - buffer, maxAvgSpeed + buffer])
      .range([height, 0]);

    const yScaleSumDistance = d3.scaleLinear()
      .domain([0, d3.max(validData, (d) => d.sum_distance)])
      .range([height, 0]);

    // Axes
    const xAxis = d3.axisBottom(this.xScale);
    const yAxisLeft = d3.axisLeft(yScaleAvgSpeed).tickFormat((d) => this.$secondsToMinutesPerKm(d));
    const yAxisRight = d3.axisRight(yScaleSumDistance);

    g.append("g").attr("transform", `translate(0,${height})`).call(xAxis);
    g.append("g").call(yAxisLeft).attr("stroke", "#257180");
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left)
      .attr("x", -height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("fill", "#257180")
      .text("Pace (min/km)");

    g.append("text")
      .attr("transform", `translate(${width + margin.right}, ${height / 2}) rotate(270)`)
      .style("text-anchor", "middle")
      .style("fill", "#FD8B51")
      .text("Kilometers");

    // Lines
    const lineAvgSpeed = d3.line()
      .x(d => this.xScale(new Date(d.start_date)))
      .y(d => yScaleAvgSpeed(d.avg_speed))
      .curve(d3.curveLinear);

    const lineSumDistance = d3.line()
      .x((d) => this.xScale(new Date(d.start_date)))
      .y((d) => yScaleSumDistance(d.sum_distance))
      .curve(d3.curveLinear);

    if (this.showAvgSpeed) {
      g.append("path")
        .datum(validData)
        .attr("fill", "none")
        .attr("stroke", "#257180")
        .attr("stroke-width", 2)
        .attr("d", lineAvgSpeed);
    }

    if (this.showSumDistance) {
      g.append("path")
        .datum(validData)
        .attr("fill", "none")
        .attr("stroke", "#FD8B51")
        .attr("stroke-width", 2)
        .attr("d", lineSumDistance);
    }

    // Draw points for short date ranges
    const dateDiff = (new Date(this.customEndDate) - new Date(this.customStartDate)) / (1000 * 60 * 60 * 24);
    if (this.customEndDate && this.customStartDate && dateDiff < 45) {
      g.selectAll("circle")
        .data(validData)
        .enter()
        .append("circle")
        .attr("cx", d => this.xScale(new Date(d.start_date)))
        .attr("cy", d => yScaleAvgSpeed(d.avg_speed))
        .attr("r", d => Math.sqrt(d.sum_distance) * 4)
        .attr("fill", "#257180");
    }
  else{
    g.append("g").attr("transform", `translate(${width},0)`).call(yAxisRight).attr("stroke", "#FD8B51");
        if (this.showAvgSpeed) {
          g.append("path")
            .datum(validData)
            .attr("fill", "none")
            .attr("stroke", "#257180")
            .attr("stroke-width", 2)
            .attr("d", lineAvgSpeed);
        }

        if (this.showSumDistance) {
          g.append("path")
            .datum(validData)
            .attr("fill", "none")
            .attr("stroke", "#FD8B51")
            .attr("stroke-width", 2)
            .attr("d", lineSumDistance);
        }
        // Brush functionality
        const brush = d3.brushX()
          .extent([
            [0, 0],
            [width, height],
          ])
          .on("start", () => this.disableHover = true)
          .on("end", this.handleBrush);

        // "click", (event) => console.info(event))

       
      
        g.append("g").attr("class", "brush").call(brush);

      // Vertical line and mousemove event
      this.verticalLine = g.append("line")
        .attr("class", "vertical-line")
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .style("opacity", 0);

        
      }
      svg.on("mousemove", (event) => this.handleMouseMove(event));
      svg.on('touchmove', (event) => this.handleMouseMove(event));
     



  // Brush and hover logic remains unchanged
}
,
    handleBrush(event) {
      // if (!event.selection) {this.disableHover = false; 
        
      if (!event.selection) {
        // No selection made, handle it as a single click
        const [mouseX] = d3.pointer(event.sourceEvent, this.$refs.svg); // Get the mouse position
        const clickedDate = this.xScale.invert(mouseX - this.margin.left); // Convert mouse position to date

        // Find the closest data point to the clicked date
        const convertedRange = this.filteredData.reduce((prev, curr) => {
          return (Math.abs(new Date(curr.start_date) - clickedDate) < Math.abs(new Date(prev.start_date) - clickedDate)) ? curr : prev;
        });
        // Optionally update the chart or perform another action
        this.$emit('brush', { startDate: convertedRange.start_date, endDate: convertedRange.end_date, callBack: function() {this.filteredData = this.data; this.drawChart()}});

        this.customStartDate = new Date(convertedRange.start_date).toISOString().split('T')[0];
        this.customEndDate = new Date(convertedRange.end_date).toISOString().split('T')[0];

        this.disableHover = false;
        return;
      } // No selection made

      const [x0, x1] = event.selection;
     
      const selectedStartDate = this.xScale.invert(x0);
      const selectedEndDate = this.xScale.invert(x1);

      this.customStartDate = new Date(selectedStartDate).toISOString().split('T')[0];
      this.customEndDate = new Date(selectedEndDate).toISOString().split('T')[0];
      this.$emit('brush', { startDate: selectedStartDate, endDate: selectedEndDate, callBack: function() {this.filteredData = this.data; this.drawChart()} });
      // this.updateCustomDateRange();
      this.disableHover = false;
    },
    handleMouseMove(event) {
      if (this.disableHover) return;
      const [mouseX] = d3.pointer(event);
      const xDate = this.xScale.invert(mouseX - this.margin.left);

      // Find the closest data point to the current mouse position
      const closestData = this.filteredData.reduce((prev, curr) => {
        return (Math.abs(new Date(curr.start_date) - xDate) < Math.abs(new Date(prev.start_date) - xDate)) ? curr : prev;
      });

      // Update the position of the vertical line
      this.verticalLine
        .attr("x1", this.xScale(new Date(closestData.start_date)))
        .attr("x2", this.xScale(new Date(closestData.start_date)))
        .style("opacity", 1);

      
      this.selectedData = closestData;
    },
  },
  computed: {
    chartIsPoints() {
      const dateDiff = (new Date(this.customEndDate) - new Date(this.customStartDate)) / (1000 * 60 * 60 * 24);
      return this.customEndDate && this.customStartDate && dateDiff < 45;
    },
  },
  watch: {
    showAvgSpeed() {
      this.drawChart();
    },
    showSumDistance() {
      this.drawChart();
    }
  },
};
</script>

<style scoped>
svg {
  background-color: white;
}

.data-details-box {
  position: absolute;
  top: 0px;
  right: 0px;
  /* Position below the graph */
  /* max-width: 50%; */
  /* border: 1px solid black; */
  padding: 10px;
  margin: auto;
  border-radius: 5px;
  font-size: 12px;
  /* Smaller text */
}

.vertical-line {
  pointer-events: none;
}

.avg-speed {
  color: #257180;
}

.sum-distance {
  color: #FD8B51;
}

.form-check-input.sum-distance:checked {
  background-color: #FD8B51;
  border-color: #FD8B51;
}

.form-check-input.avg-speed:checked {
  background-color: #257180;
  border-color: #257180;
}
</style>
