<template>
  <div class="container p-2 p-md-2">
    <!-- Search Buttons -->
    <div class="mb-3">
      <button class="btn btn-primary m-1" v-for="category in categories" :key="category" @click="fetchActivities(category)" :disabled="isLoading">
        {{ isLoading && selectedCategory === category ? 'Loading...' : `Search ${category}` }}
      </button>
    </div>

    <!-- Display fetched data -->
    <div v-if="sortedActivities.length > 0" class="mt-3 shadow p-3">
      <h3>Activities:</h3>
      <div class="row">
        <div class="col-md-6" v-for="activity in sortedActivities" :key="activity.id">
          <ActivityCard :activity="activity" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityCard from './ActivityCard.vue';
import axios from 'axios';

export default {
  name: 'ActivitySearch',
  components: {
    ActivityCard,
  },
  data() {
    return {
      categories: ['Long Runs', 'Marathon Intervals', 'Easy Runs', 'Tempo Runs'],
      activities: [],
      isLoading: false,
      selectedCategory: '',
    };
  },
  computed: {
    sortedActivities() {
      const sortedActivitiesTmp = [...this.activities].sort((a, b) => new Date(b.date) - new Date(a.date));
      return sortedActivitiesTmp;
    },
  },
  methods: {
    async fetchActivities(category) {
      this.isLoading = true;
      this.selectedCategory = category;
      const url = this.selectedCategory.toLowerCase().replace(' ', '');
      try {
        // Replace with actual API call
        const response = await axios.get(`/api/runs/${url}`);
        this.activities = response.data;
      } catch (error) {
        console.error('Error fetching activities:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>