<template>
    <div class="container">
      <div class="container-fluid">
        <!-- Header Section -->
        <div class="header px-2 pb-4">
          <UrrisComment :comment="activity.uhrisComment" ref="comment" :widthstyle="'60px'"></UrrisComment>
        </div>
  
        <!-- Body Section -->
        <div class="body">
          <!-- Tabs Navigation -->
          <div class="row mb-4">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#tab1">Analysis</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#tab2">Similar Activities</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#tab3">Up Next</a>
              </li>
            </ul>
          </div>
  
          <!-- Tab Content -->
          <div class="tab-content">
            <!-- Analysis Tab -->
            <div class="tab-pane active" id="tab1">
              <div class="row">
                <!-- Activity Summary -->
                <div class="col-12 mb-4">
                  <div class="card">
                    <div class="card-header bg-primary text-white">
                      <h5><i class="fas fa-info-circle"></i> Activity Summary</h5>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title mb-2 p-2">{{ activity.name }}</h5>
                      <table class="table table-bordered table-responsive">
                        <thead class="table-light">
                          <tr>
                            <th>Total Distance</th>
                            <th>Duration</th>
                            <th>Average Pace</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ (activity.distance / 1000).toFixed(2) }} km</td>
                            <td>{{ $formatTime(activity.elapsed_time) }}</td>
                            <td>{{ $formatMpsToMpk(activity.average_speed) }} min/km</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <!-- Intervals Grouped Section -->
                <div class="col-12 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header bg-secondary">
                      <h5><i class="fas fa-running"></i> Intervals Grouped by Distance</h5>
                    </div>
                    <div class="card-body">
                      <table class="table table-bordered table-striped">
                        <thead class="table-light">
                          <tr>
                            <th>Distance</th>
                            <th>Intervals</th>
                            <th>Avg Time</th>
                            <th>Rest Between</th>
                            <th>Avg Pace</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="group in activity.grouped_laps" :key="group.key">
                            <td>{{ group[group.length - 1].key }} m</td>
                            <td>{{ group.length - 1 }}</td>
                            <td>{{ $formatTime(group[group.length - 1].avg_time) }}</td>
                            <td>{{ avg_rest_time }}</td>
                            <td>{{ $formatMpsToMpk(((group[group.length - 1].key) /(group[group.length - 1].avg_time)  )) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  <!-- Totals Section -->
                <div class="col-12 mb-4 col-md-6">
                  <div class="card">
                    <div class="card-header bg-secondary">
                      <h5><i class="fas fa-chart-line"></i>Interval Totals</h5>
                    </div>
                    <div class="card-body">
                      <table class="table table-bordered table-striped">
                        <thead class="table-light">
                          <tr>
                            <th>Total Interval Distance</th>
                            <th>Average Pace on Intervals</th>
                            <th>Average Heartrate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><span class="badge bg-primary">{{
                              Math.round(activity.intervals?.length * activity.avg_distance_intervals)
                            }} m</span></td>
                            <td><span class="badge bg-info">{{ avg_pace_intervals }} min/km</span></td>
                            <td><span class="text-muted">N/A</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- All Intervals Section -->
                <div class="col-12 mb-4">
                  <div class="card">
                    <div class="card-header bg-success text-white">
                      <h5><i class="fas fa-list"></i> All Intervals</h5>
                    </div>
                    <div class="card-body">
                      <table class="table table-bordered table-responsive">
                        <thead class="table-light">
                          <tr>
                            <th>Distance (km)</th>
                            <th>Time</th>
                            <th>Pace</th>
                            <th>Cadence</th>
                            <th>Elevation Gain (m)</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="interval in activity.intervals" :key="interval.id">
                            <td>{{ (interval.distance / 1000).toFixed(2) }}</td>
                            <td>{{ $formatTime(interval.elapsed_time) }}</td>
                            <td>{{ $formatMpsToMpk(interval.average_speed) }}</td>
                            <td>{{ interval.average_cadence }}</td>
                            <td>{{ interval.total_elevation_gain }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                
              </div>
            </div>
  
            <!-- Similar Activities Tab -->
            <div class="tab-pane" id="tab2">
              <div class="row" v-if="activity.similar_activities?.length">
                <ActivityCard
                  class="col-12 col-md-6 mb-3"
                  v-for="sactivity in activity.similar_activities"
                  :activity="sactivity"
                  :key="sactivity.id"
                ></ActivityCard>
              </div>
            </div>
  
            <!-- Workout Suggestions Tab -->
            <div class="tab-pane" id="tab3">
              <WorkoutSuggestions :activity="activity"></WorkoutSuggestions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import axios from "axios";
import UrrisComment from '@/components/UrrisComment';
import ActivityCard from '@/components/ActivityCard';
import WorkoutSuggestions from '@/components/WorkoutSuggestions';
export default {

    watch: {
        '$route.params.id': function () {
            this.activity = {};
            this.loadActivityDetails();

        }
    },

    components: {
        UrrisComment,
        ActivityCard,
        WorkoutSuggestions
    },
    computed: {
        activityId() {
            return parseInt(this.$route.params.id);
        },
        avg_interval_time() {
            return this.$formatTime(this.activity.avg_interval_time)
        },
        avg_rest_time() {
            return this.$formatTime(this.activity.avg_rest_time)
        },
        avg_pace_intervals() {
            return this.$formatMpsToMpk(this.activity.avg_distance_intervals / this.activity.avg_interval_time)
        }
    },
    data() {
        return {
            activity: {}
        };
    },
    mounted() {

        this.loadActivityDetails();

    },

    methods: {

        loadActivityDetails() {
            axios
                .get(`api/activity/${this.activityId}`)
                .then(response => {
                    this.activity = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },


    }
};
</script>

<style scoped>
.activity-details {
    position: relative;
}

.activity-details img {
    height: auto;
}
</style>