<template>
  <div>
    <!-- Mobile Message -->
    <div v-if="isMobile && !userInteracted" @click="setUserInteracted" class="mobile-message">
      <p>Tap and rotate your device to landscape to view the chart.</p>
    </div>
    <!-- Fullscreen Chart Modal for Mobile -->
    <div v-if="isMobile && isLandscape && userInteracted" class="fullscreen-modal">
      <button @click="closeChart" class="close-btn">✖</button>
      <ResponsiveLineChart
        v-if="fullResponse && userInteracted === true"
        :keyss="renderKey" 
        ref="chartComponent"
        :data="fullResponse"
        @brush="handleBrush"
        xAxisLabel="date"
      />
    </div>

    <!-- Normal Chart for Desktop -->
    <ResponsiveLineChart
      v-if="fullResponse && !isMobile"
      ref="chartComponent"
      :data="fullResponse"
      @brush="handleBrush"
      xAxisLabel="date"
    />
  </div>
</template>

<script>
import axios from "axios";
import ResponsiveLineChart from "@/components/ResponsiveLineChart.vue";

export default {
  name: "DevelopmentOverTime",
  components: {
    ResponsiveLineChart,
  },
  data() {
    return {
      fullResponse: null,
      isMobile: this.checkIfMobile(),
      isLandscape: window.innerWidth > window.innerHeight,
      userInteracted: false,
      renderKey: 0, // Used to force re-render of the chart
    };
  },
  mounted() {
    this.fetchData();
    window.addEventListener("resize", this.checkOrientation);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkOrientation);
  },
  methods: {
    async handleBrush(dates) {
      await this.fetchData(dates);
      this.$refs.chartComponent.filteredData = this.fullResponse;
      this.$refs.chartComponent.drawChart();
    },

    async fetchData(dates) {
      let data = dates && dates.startDate && dates.endDate ? {
        startdate: dates.startDate,
        enddate: dates.endDate,
        days: (new Date(dates.endDate) - new Date(dates.startDate)) / (1000 * 60 * 60 * 24) < 45 ? 1 : 7
      } : { days: 30 };

      return axios.post("api/athletedashdevovertime", data).then(response => {
        this.fullResponse = response.data;
      });
    },
    checkIfMobile() {
      return /Mobi|Android/i.test(navigator.userAgent);
    },
    checkOrientation() {
      this.isLandscape = window.innerWidth > window.innerHeight;
      

      if (this.isLandscape && this.userInteracted) {
        this.forceChartRerender();
      }
      else{
        this.closeChart();
      }
    },
    setUserInteracted() {
      this.userInteracted = true;
      this.forceChartRerender();
    },
    forceChartRerender() {
      this.renderKey++; // Updates key, forcing Vue to re-render the chart
    },

    closeChart() {
      this.userInteracted = false;
    },
  },
};
</script>

<style scoped>
/* Message Box */
.mobile-message {
  text-align: center;
  padding: 20px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 10px;
  cursor: pointer;
}

/* Fullscreen Modal */
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  z-index: 1000;
}
</style>
