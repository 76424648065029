<template>
    <div class="container p-2 p-md-2">

 

        <div class="row g-3">
            <!-- Personal Bests -->
            <div class="col-12 col-md-6">
                <h2>Personal Bests</h2>
                <div>
                    <label for="inputMarathon" class="form-label">Marathon</label>
                    <input type="text" class="form-control" id="inputMarathon" v-model="personalBests.marathon"
                        readonly>
                </div>
                <div>
                    <label for="inputHalfMarathon" class="form-label">Half Marathon</label>
                    <input type="text" class="form-control" id="inputHalfMarathon" v-model="personalBests.halfMarathon"
                        readonly>
                </div>
                <div>
                    <label for="input10k" class="form-label">10 km</label>
                    <input type="text" class="form-control" id="input10k" v-model="personalBests.tenKm" readonly>
                </div>
                <div>
                    <label for="input5k" class="form-label">5 km</label>
                    <input type="text" class="form-control" id="input5k" v-model="personalBests.fiveKm" readonly>
                </div>
            </div>

            <!-- Goals Section -->
            <div class="col-md-6 col-12">
                <!-- Current Goals List -->
                <h2>Current Goals</h2>
                <ul class="list-group">
                    <li v-for="(goal, index) in sortedGoals" :key="goal.id"
                        class="list-group-item border-2 d-flex flex-column justify-content-between align-items-center">
                        <div class=" w-100">{{ goal.date }}
                            <p>
                                Time remaining: {{ timeRemaining(goal.date) }}
                            </p>
                        </div>
                        <div class="d-flex justify-content-between w-100">

                            <div>
                                <strong>{{ formatGoalName(goal.event) }}:</strong> {{ goal.time }}

                            </div>
                            <div>
                                <button class="btn btn-sm btn-warning me-2" @click="editGoal(goal)">Edit</button>
                                <button class="btn btn-sm btn-danger"
                                    @click="removeGoal(goal.id, index)">Remove</button>
                            </div>
                        </div>
                    </li>
                </ul>

                <h3 class="mt-4">{{ state }} Goal</h3>
                <div>
                    <label for="goalSelect" class="form-label">Event</label>
                    <select id="goalSelect" class="form-select" v-model="selectedGoal">
                        <option value="" disabled>Select an event</option>
                        <option value="marathon">Marathon</option>
                        <option value="halfMarathon">Half Marathon</option>
                        <option value="10k">10 km</option>
                        <option value="5k">5 km</option>
                    </select>
                </div>
                <div v-if="selectedGoal">
                    <label for="dateInput" class="form-label mt-2">Target Date</label>
                    <input type="date" class="form-control" id="dateInput" v-model="goalDate">
                </div>
                <div v-if="selectedGoal && goalDate">
                    <label for="timeInput" class="form-label mt-2">Target Time</label>
                    <input type="text" class="form-control" id="timeInput" v-model="goalTime" placeholder="hh:mm:ss">
                </div>
                <button type="submit" class="w-100 btn mt-2 btn-primary" :disabled="!isSaveEnabled" @click="saveGoal">
                    Save Goal
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    
    name: 'UserData',
    data() {
        return {
            personalBests: {
                marathon: '',
                halfMarathon: '',
                tenKm: '',
                fiveKm: ''
            },
            goals: [],
            selectedGoal: '',
            goalTime: '',
            goalDate: '',
            editingGoalId: null,
            state: 'Add',
            longRuns: [], // Stores fetched long runs
      isLoading: false, // Tracks loading state
      error: '', // Stores error messages
        }
    },
    computed: {
        isSaveEnabled() {
            return this.goalTime.match(/^(\d{2}):([0-5]\d):([0-5]\d)$/) && this.goalDate;
        },
        sortedGoals() {
            return [...this.goals].sort((a, b) => new Date(a.date) - new Date(b.date));
        }

    },
    mounted() {
        this.fetchPersonalBests();
        this.fetchGoals();
    },
    methods: {

    

        fetchPersonalBests() {
            axios.get('/api/personal-bests')
                .then(response => {
                    this.personalBests.fiveKm = this.$formatTime(parseInt(response.data['5']));
                    this.personalBests.tenKm = this.$formatTime(parseInt(response.data['10']));
                    this.personalBests.halfMarathon = this.$formatTime(parseInt(response.data['21.1']));
                    this.personalBests.marathon = this.$formatTime(parseInt(response.data['42.2']));

                })
                .catch(error => {
                    console.error('Error fetching personal bests:', error);
                });
        },
        fetchGoals() {
            axios.get('/api/getathletegoals')
                .then(response => {
                    this.goals = response.data;
                })
                .catch(error => {
                    console.error('Error fetching goals:', error);
                });
        },
        saveGoal() {
            if (!this.selectedGoal || !this.goalTime || !this.goalDate) return;

            const goalData = {
                event: this.selectedGoal,
                time: this.goalTime,
                date: this.goalDate
            };

            if (this.editingGoalId) {
                axios.put(`/api/savegoals/${this.editingGoalId}`, goalData)
                    .then(response => {
                        const index = this.goals.findIndex(g => g.id === this.editingGoalId);
                        if (index !== -1) this.goals[index] = response.data;
                        this.resetForm();
                        this.goals = response.data;
                    })
                    .catch(error => {
                        console.error('Error updating goal:', error);
                    });
            } else {
                axios.post('/api/addgoal', goalData)
                    .then(response => {
                        this.goals.push(response.data);
                        this.resetForm();
                        this.goals = response.data;
                    })
                    .catch(error => {
                        console.error('Error saving goal:', error);
                    });
            }
        },
        editGoal(goal) {
            this.state = 'Edit';
            this.selectedGoal = goal.event;
            this.goalTime = goal.time;
            this.goalDate = goal.date;
            this.editingGoalId = goal.id;
        },
        removeGoal(goalId) {
            axios.delete(`/api/deletegoal/${goalId}`)
                .then(response => {
                    this.goals = response.data;
                })
                .catch(error => {
                    console.error('Error deleting goal:', error);
                });
        },
        resetForm() {
            this.selectedGoal = '';
            this.goalTime = '';
            this.goalDate = '';
            this.editingGoalId = null;
        },
        formatGoalName(event) {
            const mapping = {
                marathon: 'Marathon',
                halfMarathon: 'Half Marathon',
                '10k': '10 km',
                '5k': '5 km'
            };
            return mapping[event] || event;
        },
        timeRemaining(goalDate) {
            const now = new Date();
            const goalDateObj = new Date(goalDate);

            const diffInMilliseconds = goalDateObj - now;

            if (diffInMilliseconds <= 0) {
                return 'Race is today or has passed!';
            }

            const months = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 30));
            const weeks = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 7));
            const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

            let timeRemainingText = '';

            if (months > 0) {
                timeRemainingText += `${months} months`;
            }

            if (weeks % 4 > 0) {
                if (timeRemainingText) timeRemainingText += ', ';
                timeRemainingText += `${weeks % 4} weeks`;
            }

            if (days % 7 > 0 || (months === 0 && weeks % 4 === 0)) {
                if (timeRemainingText) timeRemainingText += ', ';
                timeRemainingText += `${days % 7} days`;
            }

            return timeRemainingText;
        }
    }
}
</script>

<style scoped>
.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
