/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faBullseye, faAngleRight, faAngleDown, faHomeUser, faUtensils, faCalendarCheck, faBars, faRunning, faTachometer, faMapMarker, faRulerHorizontal, faSearch } from '@fortawesome/free-solid-svg-icons'


export function importFontAwesomeIcons (){
    /* add icons to the library */
    library.add(faBullseye, faAngleRight, faAngleDown, faHomeUser, faUtensils, faCalendarCheck, faBars, faRunning, faTachometer, faMapMarker, faRulerHorizontal , faSearch)

}
